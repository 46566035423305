import { Container } from 'react-bootstrap';
import intro from '../assets/videos/intro.mp4';

function Intro() {
    return (
        <Container fluid className="p-0">
            <section className="outter ">
                <section className="video-container">
                    <video src={intro} autoPlay loop playsInline muted></video>
                    <div className="callout">
                        <h1 className="intro-text"><b>J Ritch Productions </b></h1>
                    </div>
                </section>
            </section>


        </Container>
    );
}

export default Intro;

