import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { Container, Row} from 'react-bootstrap'

const Contact = () => {
    return (

        <div id ="contact">
            <Container fluid className='text-center'>
                <br/>
                <Row className="justify-content-center">
                    <h1 className="portfolio-text"><b>Contact Me</b></h1>
                </Row>
             
                <Row className="justify-content-center contact-logo-section">
                    <a className="contact-icons" href="https://www.instagram.com/jritch13/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} size='4x' /></a>
                    <a href="https://www.youtube.com/c/JRitchProductions/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} size='4x' /></a>
                    <a href="https://www.linkedin.com/in/jonathanritchie/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} size='4x' /></a>
                    <a href="mailto:jonathanritchie1@yahoo.ca"><FontAwesomeIcon icon={faEnvelope} size='4x' /></a>
                </Row>
            </Container>
        </div>
    )
}

export default Contact
