import { Navbar, Nav } from 'react-bootstrap';


function Header() {
    return (
        <Navbar collapseOnSelect variant="dark">
            <Navbar.Brand href="#home" className="logo">JR</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                    <Nav.Link href="#about" className="text-white">About Me</Nav.Link>
                    <Nav.Link href="#portfolio" className="text-white">Portfolio</Nav.Link>
                    <Nav.Link href="#contact" className="text-white">Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;
