import { useState } from 'react'
import { Container} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube} from '@fortawesome/free-brands-svg-icons'
import { isMobile } from 'react-device-detect';

import Slider from "react-slick";
import Capiche from "../assets/videos/Capiche.mp4"
import Dance from "../assets/videos/Dance.mp4"
import DJI from "../assets/videos/DJI.mp4"
import ErrorInCont from "../assets/videos/ErrorInCont.mp4"
import ShowYou from "../assets/videos/ShowYou.mp4"
import InTheZone from "../assets/videos/InTheZone.mp4"

import img01 from "../assets/images/img01.jpg"
import img02 from "../assets/images/img02.jpg"
import img03 from "../assets/images/img03.jpg"
import img04 from "../assets/images/img04.jpg"
import img05 from "../assets/images/img05.jpg"
import img06 from "../assets/images/img06.jpg"
import img07 from "../assets/images/img07.jpg"
import img08 from "../assets/images/img08.jpg"
import img09 from "../assets/images/img09.jpg"
import img10 from "../assets/images/img10.jpg"
import img11 from "../assets/images/img11.jpg"
import img12 from "../assets/images/img12.jpg"
import img13 from "../assets/images/img13.jpg"
import img14 from "../assets/images/img14.jpg"
import img15 from "../assets/images/img15.jpg"
import img16 from "../assets/images/img16.jpg"
import img17 from "../assets/images/img17.jpg"
import img18 from "../assets/images/img18.jpg"
import img19 from "../assets/images/img19.jpg"
import img20 from "../assets/images/img20.jpg"
import img21 from "../assets/images/img21.jpg"
import img22 from "../assets/images/img22.jpg"


const Portfolio = () => {
    let psize
    if (isMobile){
        psize = 1
    }else{
        psize = 3
    }

    const [title, setTitle] = useState('Bueller - Capiche')
    const [desc, setDesc] = useState('Music Video')
    const [url, setUrl] = useState('https://www.youtube.com/watch?v=Vq8fdVWs2TI')

    const videoData = [
        {src: Capiche, title: "Bueller - Capiche", desc: "Music Video", url: "https://www.youtube.com/watch?v=Vq8fdVWs2TI"},
        {src: DJI, title: "DJI Mavic Pro - Humber Bay Park, Toronto", desc: "Aerial Video", url: "https://youtu.be/ZdQScyC4tp8"},
        {src: ErrorInCont, title: "Error In Continuity", desc: "Short Film", url: "https://www.youtube.com/watch?v=K3b-Yf3MT1g"},
        {src: ShowYou, title: "Bueller - Show You", desc: "Music Video", url: "https://www.youtube.com/watch?v=UqhOnu6M3yg"},
        {src: Dance, title: "Fuad Musayev & Alyssa Cunningham Dance Collab", desc: "Dance Video" , url: "https://www.youtube.com/watch?v=vuVdI9mWuxI"},
        {src: InTheZone, title: "Bueller - Won't Stop / In the Zone", desc: "Music Video", url: "https://www.youtube.com/watch?v=eK_8JvYfw3o"}   
    ]

    const playVideo = (i) => {
        var vids = document.getElementsByClassName(`portfolio-video-`+i.toString())
        for (var i=0; i < vids.length; i++) vids[i].play();
    }
    const pauseVideo = (i) => {
        var vids = document.getElementsByClassName(`portfolio-video-`+i.toString())
        for (var i=0; i < vids.length; i++) vids[i].pause();
    }

    const handleChange = (oldIndex, newIndex) => {
        if(oldIndex !== newIndex) {
            setTitle(videoData[newIndex].title)
            setDesc(videoData[newIndex].desc)
            setUrl(videoData[newIndex].url)
            pauseVideo(oldIndex);
        }
        playVideo(newIndex);
    }

    const vidCarousel = {
        className: "center",
        centerMode: true,
        arrows: false,
        centerPadding: "60px",
        slidesToShow: psize,
        speed: 500,
        infinite: true,
        focusOnSelect: true,
        beforeChange: handleChange
    };

    const imgCarousel = {
        className: "center",
        centerMode: true,
        speed: 500,
        infinite: true,
        arrows:false,
        slidesToShow: psize,
        focusOnSelect: true,
        dots: true
    }

    // handleScroll: function(event){
    //     let scrollbottom = event.srcElement.body.scroll 
    // }
    return (
        <div id="portfolio">
                          
            <Container fluid className='text-center p-0'>
                <h1 className="portfolio-text"><b>Portfolio</b></h1>
                
                <h2><b>Videos</b></h2>
                <Slider {...vidCarousel}>
                    {videoData.map(function(video, i){
                        return (
                            <div className="portfolio-video" key={i}>
                                <video loop muted className={`portfolio-video-`+i.toString()} src={video.src}  />
                            </div>
                        )
                    })}
                </Slider>
                <h3 className="video-link" style={{ color: 'white'}}><a target="_blank" rel="noopener noreferrer" href={url}><FontAwesomeIcon icon={faYoutube} color ="white" size='1x' />&nbsp;{title}</a></h3>
                <p>{desc}</p>
                <br/>
                <br/>
                
                <h2><b>Photography</b></h2>
                <Slider {...imgCarousel}>
                    <div className="portfolio-image">
                        <img src={img01}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img02}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img03}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img04}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img05}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img06}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img07}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img08}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img09}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img10}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img11}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img12}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img13}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img14}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img15}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img16}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img17}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img18}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img19}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img20}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img21}></img>
                    </div>
                    <div className="portfolio-image">
                        <img src={img22}></img>
                    </div>
                </Slider>   
            </Container>
        </div>
    )
}

export default Portfolio
