import { Col, Container, Image, Row } from "react-bootstrap"
import profile from '../assets/images/profile.jpg'

const About = () => {
    return (
        <div id="about">
            <h1 className="portfolio-text" ><b>About Me</b></h1>
            <Container  >
                {/* <Row className="justify-content-center"> */}
                    {/* <h1 className="portfolio-text" ><b>About Me</b></h1> */}
                {/* </Row> */}
                <Row >
                    <Col md={8} >
                        
                        <h2 >Hello there!</h2>
                        <p>My name is <b>Jonathan Ritchie</b>. I am a <b>Cinematographer / Filmmaker</b> based in Toronto.
                            <br /> <br />
                            I can help fulfill your vision, whether it be for a narrative film project, music video,
                            studio setting or an event. With my talents, I hope to create something unique that will captivate and inspire.
                            <br /> <br />
                            I graduated from the <span class= "red-text">Video Design &amp; Production program at George Brown College</span>, where I honed my
                            skills in film production. I have experience working as a 1st AC, using various cinema cameras and follow focus systems.
                            <br /> <br />
                            I use programs including <span class="red-text">DaVinci Resolve, Premiere Pro &amp; After Effects</span> for my projects.
                            I'm open to many genres and forms of art, and as such, my work has a wide range. With that said,
                            I hope there's something here for you to enjoy! 
                            <br /> <br />
                            <b>Contact me below for inquiries!</b>
                        </p>
                    </Col>
                    <Col md={4}>
                        <Image fluid src={profile} />
                    </Col>

                </Row>


            </Container>
        </div>
    )
}

export default About
